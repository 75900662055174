import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header (props) {

    const selectoresp = <p><strong>español</strong> / english</p>;
    const selectoreng = <p>español / <strong>english</strong></p>;

    return (
        <div className="header-top">
            
            <div className="header-top__logo">
            <div className="lang-selector" onClick={props.setLng}>
                {props.lang === "esp" ? selectoresp : selectoreng}
            </div>
                <Link to="/">  
                    <img src="../logo.png" />
                </Link>
            </div>
            <nav className="header-top__nav">
                <Link className="header-top__nav--link" to="/services">
                    {props.lang === "esp" ? "Servicios" : "Services"}
                </Link>

                <Link className="header-top__nav--link" to="/us">
                    {props.lang === "esp" ? "Nosotros" : "About"}
                </Link>

                <Link className="header-top__nav--link" to="/contact">
                    {props.lang === "esp" ? "Contáctenos" : "Contact us"}
                </Link>
            </nav>
        </div>
    );
}