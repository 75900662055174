import DerechoEmpresarial from "./content/DerechoEmpresarial";
import NegociosInternacionales from "./content/NegociosInternacionales";
import SoftLanding from "./content/SoftLanding";
import SostenibilidadEmpresarial from "./content/SostenibilidadEmpresarial";

export default function ServiceBody (props) {
    const title = props.pageId.serviceId;
    let tit = null;
    let stit = null;
    let cont = null;

    if(title === "business-law"){
        tit = props.lang === "esp" ? <h1>Derecho<br></br>Empresarial</h1> : <h1>Business<br></br>Law</h1>;
        stit = props.lang === "esp" ? <h2>“Todo el respaldo legal<br></br>que tu empresa necesita.”</h2> : <h2>“All the legal support<br></br>that your company needs.”</h2>;
        cont = <DerechoEmpresarial lang={props.lang}/>;
    }

    else if (title === "international-business"){
        tit = props.lang === "esp" ? <h1>Negocios <br></br>Internacionales</h1> : <h1>International <br></br>Business</h1>;
        stit = props.lang === "esp" ? <h2>“Juntos frente a los retos <br></br>de los nuevos mercados.”</h2> : <h2>“Together facing the <br></br>challenges of new markets”</h2>;
        cont = <NegociosInternacionales lang={props.lang}/>;
    }

    else if (title === "softlanding"){
        tit = <h1>Softlanding</h1>;
        stit = props.lang === "esp" ? <h2>“A tu lado, para aconsejarte <br></br>las estrategias correctas <br></br>en cada negocio”</h2> : <h2>“At your side,  <br></br>to advise you on the correct  <br></br>strategies in each business”</h2>;
        cont = <SoftLanding lang={props.lang}/>;
    }

    else if (title === "corporate-sustainability"){
        tit = props.lang === "esp" ? <h1>Responsabilidad<br></br> Social Empresarial <br></br> y Sostenibilidad</h1> :  <h1>Corporate<br></br> Social Responsability <br></br> and Sustainability</h1>;
        stit = props.lang === "esp" ? <h2>“Compartimos la misma visión, crear <br></br>empresas para un mundo mejor, <br></br>transformando la economía global  <br></br>para beneficiar a las personas, <br></br>comunidades y al planeta.”</h2> : <h2>“We share the same vision,  <br></br>create companies for a better world,<br></br>transforming the global economy  <br></br>to benefit people,<br></br>communities and the planet.”</h2>;
        cont = <SostenibilidadEmpresarial lang={props.lang}/>;
    }
    
    return (
        <div className="service-body">
            <div className="service-body-up">
                <div className="service-body__title">
                    {tit}
                </div>

                <div className="service-body__subtitle">
                    {stit}
                </div>
            </div>
            { cont }
        </div>
    );
}