import FiftyFiftyHeroCard from "./components/FiftyFiftyHeroCard";
import Footer from "./components/Footer";
import FullColverImage from "./components/FullCoverImage";
import Header from "./components/Header";
import JumboHeader from "./components/JumboHeader";
import MainTextBox from "./components/MainTextBox";
import SeventyThirtyHeroCont from "./components/SeventyThirtyHeroCont";
import StaffPeople from "./components/StaffPeople";
import StatsBoard from "./components/StatsBoard";

export default function Home(props) {

    return (
        <div>
            <JumboHeader lang={props.settings.lng}/>
            <MainTextBox lang={props.settings.lng}/>
            <FiftyFiftyHeroCard lang={props.settings.lng}/>
            <StatsBoard lang={props.settings.lng}/>
            <FullColverImage />
            <SeventyThirtyHeroCont lang={props.settings.lng}/>
            <StaffPeople lang={props.settings.lng} />
            <Footer lang={props.settings.lng}/>
        </div>
    );
}