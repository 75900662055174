import { Link } from "react-router-dom";

export default function SeventyThirtyHeroCont(props) {

  const contesp = <div className="seventy-thirty-hero__main">
    <h1>Ahora somos una empresa <span className="seventy-thirty-hero__main-span">BIC</span></h1>

    <img src="./img/iconrow.png" />
    <p>Nos interesa el bienestar de las personas y creemos en generar valor económico, social y ambiental. Trabajando juntos por un mundo mejor.</p>

    <Link className="seventy-thirty-hero__button" to="/bic"> VER MÁS</Link>
  </div>;

  const conteng = <div className="seventy-thirty-hero__main">
  <h1>Now we are a <span className="seventy-thirty-hero__main-span">BIC</span> company.</h1>

  <img src="./img/iconrow.png" />
  <p>We are interested in the well-being of people and we believe in generating economic, social and environmental value.</p>

  <Link className="seventy-thirty-hero__button" to="/bic"> VIEW MORE</Link>
  </div>;

  return (
    <div className="seventy-thirty-hero">
      {props.lang === "esp" ? contesp : conteng}
    </div>
  );
}
