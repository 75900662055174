import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import FiftyFiftyHeroCard from "./FiftyFiftyHeroCard";
import Footer from "./Footer";
import FooterLite from "./FooterLite";
import FullColverImage from "./FullCoverImage";
import Header from "./Header";
import JumboHeader from "./JumboHeader";
import MainTextBox from "./MainTextBox";
import MainTextBox2 from "./MainTextBox2";
import SeventyThirtyHeroCont from "./SeventyThirtyHeroCont";
import StaffPeople from "./StaffPeople";
import StatsBoard from "./StatsBoard";

export default function Contact(props) {

    const focu = useRef();

    useEffect(()=> {
        focu.current.scrollIntoView({behavior: 'smooth'});
    });

    return (
        <div>
            <JumboHeader lang={props.settings.lng} setLng={props.setLng}/>
            <MainTextBox lang={props.settings.lng} setLng={props.setLng}/>
            <FiftyFiftyHeroCard lang={props.settings.lng} setLng={props.setLng}/>
            <StatsBoard lang={props.settings.lng} setLng={props.setLng}/>
            <FullColverImage />
            <SeventyThirtyHeroCont lang={props.settings.lng} setLng={props.setLng}/>
            <StaffPeople lang={props.settings.lng} setLng={props.setLng}/>
            <Footer lang={props.settings.lng} setLng={props.setLng}/>
            <div ref={focu}></div>
        </div>
    );
}