import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

export default function ContactForm(props) {

    function mail2() {
        window.open(
            'mailto:info@9alliance.co',
            '_blank'
          );
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_5mbzrxe', 'template_fdlm84g', form.current, 'VoR-d7PX9-Kt-CdIO')
        .then((result) => {
            alert(result.text);
        }, (error) => {
            alert(error.text);
        });
    };

    return (
        <div>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <div className="contact-form__header">
                    <div className="contact-form__columnA">
                        <div className="contact-form__column-bloq">
                            <input type="text"  name="user_name" placeholder={props.lang === "esp" ? "NOMBRE" : "NAME"}>
                            
                            </input>
    
                            <input type="email" name="user_email" placeholder="EMAIL">
                                
                            </input>
                        </div>
                    </div>

                    <div className="contact-form__columnB">
                        <div className="contact-form__column-bloq">
                            <textarea name="message" className="contact-form__column-bloq-txtbox" rows="5" cols="50" placeholder={props.lang === "esp" ? "MENSAJE" : "MESSAGE"}>
                            </textarea>
                            <label className="contact-form__column-bloq-chkbox" ><input type="checkbox" id="cbox1" value="first_checkbox" /> {props.lang === "esp" ? "Acepto los terminos de la " : "I accept the "} <a target="_blank" href="/carta de proteccion de datos.pdf"> {props.lang === "esp" ? "política de privacidad." : "privacy policy."} </a> </label>
                        </div>
                    </div>
                </div>
                
                <div className="contact-form__footer">
                    <div className="contact-form__footer-colA">
                        <div className="contact-form__lowcard">
                            <div className="contact-form__footer-button">
                                <button className="contact-form__button">{props.lang === "esp" ? "ENVIAR" : "SEND"}</button>
                            </div>
                            <div className="contact-form__footer-card">
                                <p><span>{props.lang === "esp" ? "Contáctenos:" : "Contact us:"}<br></br>
                                {props.lang === "esp" ? "Oficina Principal" : "Main Ofice:"} </span><br></br>
                                Carrera 9 No. 113 – 52<br></br>
                                Oficina 1404 - 1405<br></br>
                                Torres Unidas 2 <br></br>
                                Bogotá D.C. · Colombia
                                <br></br>
                                <strong onClick= { mail2 }>
                                info@9alliance.co
                                </strong>
                                <br></br>
                                +57 (601) 629 0266
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form__footer-colB">
                        <div className="contact-form__lowcard">
                            <div className="contact-logo">
                                <img src="../img/logow.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}