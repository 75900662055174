export default function SostenibilidadEmpresarial(props){
    return (
        <div className="service-body-down">
                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv04_1.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Estructuración de Empresas de Beneficio Interés Colectivo:" : "Structuring of Collective Interest Benefit Companies:"}</h3>
                        <p>{props.lang === "esp" ? "Te asesoramos y acompañamos en el proceso de certificar a tu empresa como parte de las Empresas de Sistema BIC para conocer los beneficios, objetivos, características  y requisitos fundamentales para cumplir la condición legal de Beneficio e Interés Colectivo (BIC).": "We advise and accompany you in the process of certifying your company as part of the BIC System Companies to learn about the benefits, objectives, characteristics and fundamental requirements to comply with the legal condition of Collective Benefit and Interest (BIC)."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv04_2.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Implementación de programas de responsabilidad social empresarial:" : "Implementation of corporate social responsibility programs:"}</h3>
                        <p>{props.lang === "esp" ? "Los servicios de una consultoría de responsabilidad social se enfocan en: política de sostenibilidad, asesoría en programas de voluntariado, redacción de contenido para un informe de sostenibilidad y gestión de comunicaciones.": "The services of a social responsibility consultancy focus on: sustainability policy, advice on volunteer programs, content writing for a sustainability report and communications management."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv04_3.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Acreditación empresas tipo B:" : "Accreditation type B companies:"}</h3>
                        <p>{props.lang === "esp" ? "Te apoyamos y asesoramos para convertirte en una empresa socialmente responsable. Ser empresa B te permite ser parte de una comunidad global de empresas reconocidas, identificando nuevas oportunidades desde la innovación empresarial.": "We support and advise you to become a socially responsible company. Being a B company allows you to be part of a global community of recognized companies, identifying new opportunities from business innovation."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv04_4.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Liderazgo Sostenible y Propósito en las Empresas:" : "Sustainable Leadership and Purpose in Companies:"}</h3>
                        <p>{props.lang === "esp" ? "Es por esto que te asesoramos para que tu empresa lidere y trabaje desde el propósito su compromiso con el cuidado de las personas y del planeta. Te apoyamos en generar espacios de transformación dentro de los equipos de trabajo, para que las personas sean partícipes de la definición del propósito.": "We advise you so that your company can lead and work for the purpose of it´s commitment to care for people and the planet. We support you in generating spaces for transformation within work teams, so that people are participants in defining the purpose. "}</p>
                    </div>
                </div>
            </div>
    );
}