export default function BicBody(props){
    return (
        <div className="bic-body">
            <div className="bic-body-columnB">
                <div className="bic-body-up">
                    <div className="bic-body__title">
                        BIC
                    </div>

                    <div className="bic-body__subtitle">
                    {props.lang === "esp" ? "Como empresa nos unimos a esta transformación empresarial para generar impactos positivos a través de nuestra misión, visión, valores y acciones de Sistema BIC ." : "As a company we join this business transformation to generate positive impacts through our mission, vision, values and actions of Sistema BIC.  "}
                    </div>
                </div>

                
                <div className="bic-body-down">
                    <p>
                    <strong>{props.lang === "esp" ? "MISIÓN 9A": "9A MISION"}</strong><br></br>
{props.lang === "esp" ? "Proporcionamos a nuestros clientes servicios de consultoría y de gestión con enfoque en la innovación, eficiencia y amabilidad para el desarrollo de sus organizaciones. Ofrecemos servicios de calidad que contribuyen a crear confianza y generar valor en nuestros clientes, colaboradores, accionistas y a la comunidad desde un liderazgo responsable con desarrollo de un crecimiento rentable en armonía con la responsabilidad social corporativa." : "We provide our clients with consulting and management services focused on innovation, efficiency and friendliness for the development of their organizations. We offer quality services that contribute to creating trust and generating value in our clients, collaborators, shareholders and the community from responsible leadership with the development of profitable growth in harmony with corporate social responsibility. "}<br></br><br></br>
<strong>{props.lang === "esp" ? "VISIÓN 9A": "9A VISION"}</strong> <br></br>
{props.lang === "esp" ? "Ser una organización comprometida por su excelencia en satisfacer las necesidades de los clientes, con soluciones integrales e innovadoras; logrando así el reconocimiento por nuestra mentalidad de servicio al cliente, y por el profesionalismo de nuestro equipo de trabajo con altos estándares éticos y experiencia en cada disciplina. Convirtiéndonos en una organización con propósito hacia la sostenibilidad que obtiene rentabilidad en sus servicios, proporciona condiciones de trabajo y calidad de vida; y aportar a la comunidad como motor para el cambio global." : "Being an organization committed to its excellence in meeting customer needs, with comprehensive and innovative solutions, let us have achieved recognition for our customer service mentality, and for the professionalism of our work team with high ethical standards and experience in each discipline. Becoming an organization with a purpose towards sustainability that obtains profitability in its services, provides working conditions and quality of life; and contribute to the community as an engine for global change."}<br></br><br></br>
<strong>{props.lang === "esp" ? "Valores": "Values"}</strong><br></br>
{props.lang === "esp" ? "Sostenibilidad": "Sustainability"}<br></br>
{props.lang === "esp" ? "Innovación": "Innovation"}<br></br>
{props.lang === "esp" ? "Justicia": "Justice"}<br></br>
{props.lang === "esp" ? "Verdad": "Honesty"}<br></br>
{props.lang === "esp" ? "Comunidad": "Community"}<br></br>
{props.lang === "esp" ? "Igualdad": "Equality"}<br></br>
<br></br><br></br>
<strong>{props.lang === "esp" ? "Acciones": "Actions:"}</strong><br></br>
<br></br>
<strong>{props.lang === "esp" ? "Modelo de Negocio:" : "Business model"}</strong><br></br>
•{props.lang ==="esp" ? "Adquirimos bienes y servicios de preferencia local, que implementen normas equitativas y ambientales.":"We acquire locally preferred goods and services that implement equitable and environmental standards. "}<br></br>
•	{props.lang ==="esp" ? "Promovemos prácticas de comercio justo":"We promote fair trade practices."}<br></br><br></br>
<strong>{props.lang ==="esp" ? "Gobierno Corporativo": "Corporate governance"}</strong><br></br>
•	{props.lang ==="esp" ? "Expandimos la diversidad en la composición de nuestra empresa." : "We expanded diversity in the composition of our company"}<br></br>
•	{props.lang ==="esp" ? "Expresamos nuestra misión al interior y exterior de la empresa" : "We express our mission inside and outside the company labor practices. "}<br></br><br></br>
<strong>{props.lang ==="esp" ? "Prácticas laborales": "Labor Practices"}</strong><br></br>
•	{props.lang ==="esp" ? "Brindamos remuneración salarial razonable para nuestros colaboradores con estándares de equidad." : "•	We provide reasonable salary remuneration for our collaborators with equity standards. "}<br></br>
•	{props.lang ==="esp" ? "Capacitamos y desarrollamos profesionalmente a nuestros colaboradores." : "We professionally train and develop our collaborators."}<br></br>
•	{props.lang ==="esp" ? "Brindamos opciones de empleo que permitan flexibilidad en la jornada laboral" : "We offer employment options that allow flexibility in the working day."} <br></br><br></br>
<strong>{props.lang === "esp" ? "Prácticas ambientales": "Environmental Practices"}</strong><br></br>
•	{props.lang ==="esp" ? "Implementamos sistemas de iluminación energéticamente eficientes" : "We implement energy efficient lighting systems."}  <br></br>
•	{props.lang ==="esp" ? "Promovemos campañas de reciclaje y de reutilización de desperdicios; para crear consciencia en nuestros colaboradores y la comunidad." : "We promote recycling and waste reuse campaigns; to create awareness in our collaborators and the community."}  <br></br><br></br>
<strong>{props.lang ==="esp" ? "Prácticas con la comunidad":"Practices with the community"}</strong><br></br>
•	{props.lang ==="esp" ? "Incentivamos actividades de voluntariado y generamos alianzas con fundaciones que apoyan obras sociales en interés de la comunidad." : "We encourage volunteer activities and create alliances with foundations that support social works in the interest of the community."}
                    </p>
                </div>
            </div>

            <div className="bic-body-columnA">
                <img src="../img/bigflower.png" />
            </div>
        </div>
    );
}