import BannerFooter from "./BannerFooter";
import ContactForm from "./ContactForm";
import Socials from "./Socials";

export default function Footer(props){
    return (
        <div className="footer-box">
            <ContactForm lang={props.lang}/>
            <BannerFooter />
            <Socials/>
            <div className="foot-copyright">
                <p>© 2022 | 9Alliance Copyright® | Bogotá - Colombia</p>
            </div>
        </div>
    );
}