import { Link } from "react-router-dom";

export default function MiniSideNav(props){
    const sublink = props.pageId.serviceId;
    let sl = "";

    if(sublink === "business-law")props.lang === "esp" ? sl = "Derecho Empresarial" : sl = "Business Law";
    else if(sublink === "international-business")props.lang === "esp" ? sl = "Negocios Internacionales" : sl = "International Business";
    else if(sublink === "softlanding")sl = "Softlanding";
    else if(sublink === "corporate-sustainability")props.lang === "esp" ? sl = "Responsabilidad Social Empresarial y Sostenibilidad" : sl = "Corporate Social Responsability and sustainability";
    else if(sublink === "quality")props.lang === "esp" ? sl = "Calidad" : sl = "Quality";

    return (
        
        <div className="minisidenav">
            <nav>
                <Link to="">
                &nbsp;Inicio
                </Link> &nbsp;/
                <Link to="/services">
                &nbsp;{props.lang === "esp" ? "Servicios" : "Services"}
                </Link>  &nbsp;/
                <Link to=".">
                &nbsp;{sl}
                </Link> &nbsp; /
                
            </nav>
        </div>
    );
}