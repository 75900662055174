export default function SoftLanding(props){
    return (
        <div className="service-body-down">
                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv03_1.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Apertura de la sociedad en Colombia:": "Opening of the company in Colombia:"}</h3>
                        <p>{props.lang === "esp" ? "Te ofrecemos una solución integral y simplificada para establecer y mantener su vehículo corporativo en Colombia.": "We offer you a comprehensive and simplified solution to form and maintain your corporate entity in Colombia."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv03_2.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Domicilio:": "Domicile:"}</h3>
                        <p>{props.lang === "esp" ? "Nuestro domicilio es su domicilio. Utiliza nuestras oficinas como domicilio legal para todos los efectos en Colombia.": "Our home is your home. Use our offices as legal domicile for all purposes in Colombia."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv03_3.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Representación Legal/Apoderamiento:": "Legal Representation/Power of Attorney: "}</h3>
                        <p>{props.lang === "esp" ? "Te representamos ante todas las entidades públicas y privadas en Colombia.": "We represent you before all public and private entities in Colombia."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv03_4.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Contabilidad:": "Accounting:"}</h3>
                        <p>{props.lang === "esp" ? "Nuestro equipo experto contable orienta todas tus operaciones en territorio colombiano, generando la preparación y presentación de todos los impuestos locales, preparación de estados financieros y mantenimiento de los libros contables.": "Our expert accounting team will guide all your operations in Colombian territory, including,  preparation and presentation of all local taxes, preparation of financial statements and maintenance of accounting records."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv03_5.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Recursos Humanos:": "Human Resources: "}</h3>
                        <p>{props.lang === "esp" ? "Dirigimos el proceso de contratación de los colaboradores en tu vehículo colombiano, integrando todos los trámites legales y administrativos necesarios para contratar exitosamente al colaborador.": "We direct the process of hiring employees for Your Colombian Entity, integrating all the legal and administrative procedures necessary to successfully hire the Labor."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv03_6.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Servicios Administrativos:": "Administrative services:"}</h3>
                        <p>{props.lang === "esp" ? "Nuestro equipo ayuda a solucionar cualquier tema administrativo que se genere en tu operación en Colombia, incluyendo obtención de licencias, control de tareas, entre otros.": "Our team helps solve administrative issues that arise in your operations in Colombia, including obtaining licenses, task control, etc.."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv03_7.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Tesorería:": "Treasury:"}</h3>
                        <p>{props.lang === "esp" ? "Realizamos el manejo profesional y seguro de tus recursos económicos en Colombia, incluyendo: Efectivo, cuentas bancarias y custodia de chequera.": "We carry out professional management and insurance of your economic resources in Colombia, including: Cash, bank accounts and checkbook custody."}</p>
                    </div>
                </div>
            </div>
    );
}