export default function MainTextBox (props) {

    const contesp = <p><span className="maintextbox-bold">Estamos contigo</span> en cada paso, somos un  equipo especializado en brindar servicios integrales en derecho empresarial, negocios internacionales, softlanding y responsabilidad social empresarial y sostenibilidad; para que tú y tu empresa resuelvan de manera exitosa los desafíos del mercado cuando y donde lo necesiten.</p>;

    const conteng = <p>We are with you at every step of the journey, we are a team specialized in providing comprehensive services in business law, international business, softlanding and corporate social responsibility and sustainability; for you and your company to successfully solve market challenges when and where you need it.</p>;
    return (
        <div className="maintextbox">
            {props.lang === "esp" ? contesp : conteng}
        </div>
    );
}