import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import FooterLite from "./FooterLite";
import Header from "./Header";
import MiniSideNav from "./MiniSideNav";
import ServiceBody from "./ServiceBody";
import ServiceHeader from "./ServiceHeader";

export default function ServiceDetail(props){

    const focu = useRef();
    const slug = useParams();

    useEffect(()=> {
        focu.current.scrollIntoView({behavior: 'smooth'});
    });

    return (
        <div>
            <div ref={focu}></div>
            <ServiceHeader pageId={slug} lang={props.settings.lng} setLng={props.setLng}/>
            <MiniSideNav pageId={slug} lang={props.settings.lng} setLng={props.setLng}/>
            <ServiceBody pageId={slug} lang={props.settings.lng} setLng={props.setLng}/>
            <Footer lang={props.settings.lng} setLng={props.setLng}/>
        </div>
    );
}