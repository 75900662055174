import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import FooterLite from "./FooterLite";
import Header from "./Header";
import MiniSideNav from "./MiniSideNav";
import ServiceBody from "./ServiceBody";
import ServiceHeader from "./ServiceHeader";

export default function Quality(props){

    const focu = useRef();

    useEffect(()=> {
        focu.current.scrollIntoView({behavior: 'smooth'});
    });

    return (
        <div>
            <div ref={focu}></div>
            <MiniSideNav pageId={{
                "serviceId": "quality"
            }} lang={props.settings.lng} setLng={props.setLng}/>
            <div className="service-body">
                <div className="service-body-up">
                <img src="./img/badge01.png" />
                <div className="service-body__title">
                    {props.settings.lng === "esp" ? <h1>Estamos<br></br> comprometidos<br></br> con la <strong className="service-body__title-bld" >Calidad</strong></h1> : <h1>We are<br></br> committed to <br></br>  <strong className="service-body__title-bld" >Quality</strong></h1>}
                </div>

                <div className="service-body__rack-txt">
                    <p>{props.settings.lng === "esp" ? "9 ALLIANCE S.A.S BIC a través de su prestación de servicios especializados integrales de consultoría en derecho empresarial, softlanding, negocios internacionales, procesos de gestión humana y administración empresarial, está comprometido con la satisfacción del cliente, suministrando oportunamente servicios que cumplan con los requisitos especificados y la normatividad legal vigente y de otra índole aplicable; a través de un recurso humano competente y la mejora continua del sistema de gestión de calidad." : "9 ALLIANCE S.A.S BIC through its provision of comprehensive specialized consulting services in business law, softlanding, international business, human management processes and business administration, is committed to customer satisfaction, providing timely services that meet the specified requirements and current legal and other applicable regulations; through a competent human resource and the continuous improvement of the quality management system."}</p>
                </div>
                </div>
            </div>
            <Footer lang={props.settings.lng} setLng={props.setLng}/>
        </div>
    );
}