import { Link } from "react-router-dom";
import ServiceMenu from "./ServicesMenu";

export default function JumboHeader (props) {
    return (
        <div className="jumbofather">
            <div className="jumbo">
                <h1 className="jumbo-title">
                    { props.lang === "esp" ? "Tu negocio es nuestro negocio" : "Your business is our business" }
                </h1>
                <ServiceMenu lang={props.lang}/>
            </div>
        </div>
    );
}