export default function DerechoEmpresarial(props){
    return (
        <div className="service-body-down">
                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv01_1.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Estructuración Jurídica de Negocios:" : "Business Legal Structuring:"} </h3>
                        <p>{props.lang === "esp" ? "Te brindamos apoyo legal necesario para impulsar tus planes de negocios, garantizando que los mismos se basen en fundamentos legalmente sólidos, sustentados con toda la información relevante y reduciendo el riesgo de su operación." : "We will provide you with the necessary legal support to promote your business plans,   based on solid legal foundations, supported by all the relevant information and reducing the risk of your operation."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv01_2.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Fusiones y Adquisiciones:" : "Mergers & Acquisitions:"} </h3>
                        <p>{props.lang === "esp" ? "Desarrollamos e implementamos estrategias innovadoras y eficientes en las transacciones de compra, venta y fusión de empresas. Nuestro equipo experto te asegura la confianza necesaria para que emprendas operaciones de adquisición, fusión y alianzas comerciales." : "We develop and implement innovative and efficient strategies in purchase, sale and merger of business transactions. Our experienced expert team will provide advice for you  to undertake acquisition, merger and commercial alliance operations. "}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv01_3.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Gestión de Patrimonios de Empresas de Familia:" : "Wealth Management of Family Businesses:"} </h3>
                        <p>{props.lang === "esp" ? "Planificamos y diseñamos las alternativas jurídicas más convenientes en aras de proteger y aumentar el patrimonio familiar y sus activos, de acuerdo con las necesidades de cada empresa y cada familia." : "We plan and design the most convenient legal alternatives in order to protect and increase family wealth and assets, according to the needs of each company and each family."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv01_4.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Derecho Contractual Público y Privado:" : "Public and Private Contract Law:"} </h3>
                        <p>{props.lang === "esp" ? "Te brindamos el soporte necesario en procesos de contratación frente a entidades tanto públicas como privadas. Revisamos el contenido de las disposiciones contractuales y realizamos el control legal correspondiente a las condiciones y obligaciones adquiridas por nuestros clientes, teniendo en cuenta su capacidad administrativa." : "We provide you with the necessary support in contracting processes with both public and private entities. We review the content of the contractual provisions and carry out the legal control corresponding to the conditions and obligations of the contract entered into by our clients."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv01_5.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Derecho Tributario:" : "Tax Law"} </h3>
                        <p>{props.lang === "esp" ? "Gestamos estrategias dentro del marco legal que generan múltiples beneficios económicos por ahorro y eficiencias tributarias. Optimizamos tu posición mediante una visión global de los factores tributarios en relación con el comercio internacional, normas fiscales, estructuración corporativa, financiación y disputas en múltiples jurisdicciones." : "We develop strategies within the legal framework that generate multiple economic benefits through savings and tax efficiencies. We optimize your position through a global vision of tax factors in relation to international trade, tax regulations, corporate structuring, financing and disputes in multiple jurisdictions."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv01_6.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Derecho Laboral:" : "Labor Law:"} </h3>
                        <p>{props.lang === "esp" ? "Regulamos todos los derechos y deberes tanto de los trabajadores como de nuestros clientes en todos los aspectos vinculados con la relación laboral, tales como salario, horas de trabajo, tipos de contrato, despidos y demás. Con el fin de crear una relación laboral saludable y poder solucionar cualquier tipo de conflicto entre las partes." : "We help to regulate all the rights and duties of both workers and our clients in all aspects related to the employment relationship, such as salary, working hours, types of contract, dismissal and others. In order to create a healthy working relationship and be able to solve any type of conflict between these parties."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv01_7.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Trámites y Registros:" : "Procedures and Records:"} </h3>
                        <p>{props.lang === "esp" ? "Gestionamos para ti todo tipo de trámites y registros ante entidades de control que requieras para la realización de tus negocios, tales como, registros sanitarios, visas, marcas, lemas, patentes, permiso ICA, entre otros." : "We manage for you all kinds of procedures and registrations before governmental  entities that you require to carry out your business, such as health registrations, visas, trademarks,  patents, ICA permit, etc."}</p>
                    </div>
                </div>
            </div>
    );
}