export default function FiftyFiftyHeroCard(props){

    const contesp = <div className="fifty-contentbox">
    <h1>Crecemos <br></br><span className="fifty-content__bold">Contigo</span></h1>
    <p>Contamos con un ADN innovador que nos permite adaptarnos ágilmente a la transformación empresarial. Nuestra fortaleza es prestar servicios con los más altos estándares éticos y profesionales. </p> 
    </div>;

    const conteng = <div className="fifty-contentbox">
    <h1>We grow <br></br><span className="fifty-content__bold">Together</span></h1>
    <p>We have an innovative DNA that allows us to quickly adapt to business transformation. Our strength is to provide services with the highest ethics and professionalism.</p> 
    </div>;
    
    return (
        <div className="fifty-holder">
            <div className="fifty-image"></div>
            <div className="fifty-content">            
                {props.lang === "esp" ? contesp : conteng}
            </div>
        </div>
    );
}