import { Link } from "react-router-dom";

export default function StaffPeople(props){

    const contesp = <div className="staffpeople-subtitle">
    <p className="staffpeople-subtitle__txt">
    <span className="staffpeople-subtitle__bold">Somos un equipo</span> profesional dirigido por líderes expertos y confiables.
    </p>
</div>;

const conteng = <div className="staffpeople-subtitle">
<p className="staffpeople-subtitle__txt">
<span className="staffpeople-subtitle__bold">We are a professional team</span>  led by experienced and trusted leaders
</p>
</div>;

    return (
        <div className="staffpeople-father">
            <div className="staffpeople-header">
                {props.lang === "esp" ? contesp : conteng}
            </div>

            <div className="staffpeople">
                <div className="staffpeople-box">
                <div className="staffpeople-box__card staffpeople-box__card_up">
                        <div className="staffpeople-box__card-image">
                            <img alt="Bhagwant Singh Rattan" src="./img/staff1.png" />
                        </div>

                        <div className="staffpeople-box__card-description">
                             <h1 className="staffpeople-box__card-description">
                                Bhagwant Singh Rattan
                             </h1>
                             <h2>
                             Presidente
                             </h2>
                             <p>
                                baggy.rattan@9alliance.co
                             </p>
                        </div>
                    </div>

                    <div className="staffpeople-box__card  staffpeople-box__card_mid">
                        <div className="staffpeople-box__card-image">
                            <img alt="Pilar Rattan" src="./img/staff2.png" />
                        </div>

                        <div className="staffpeople-box__card-description">
                             <h1 className="staffpeople-box__card-description">
                             Pilar Rattan
                             </h1>
                             <h2>
                             Chief Executive Officer - CEO
                             </h2>
                             <p>
                             pilar.rattan@9alliance.co
                             </p>
                        </div>
                    </div>

                    <div className="staffpeople-box__card   staffpeople-box__card_down">
                        <div className="staffpeople-box__card-image">
                            <img alt="Marcela Garcia" src="./img/staff3.png" />
                        </div>

                        <div className="staffpeople-box__card-description">
                             <h1 className="staffpeople-box__card-description">
                             Marcela Garcia
                             </h1>
                             <h2>
                             Directora de Comercio Internacional
                             </h2>
                             <p>
                             marcela.garcia@9alliance.co
                             </p>
                        </div>
                    </div>

                    <div className="staffpeople-box__card   staffpeople-box__card_mid">
                        <div className="staffpeople-box__card-image">
                            <img alt="Juan David Orjuela" src="./img/staff4.png" />
                        </div>

                        <div className="staffpeople-box__card-description">
                             <h1 className="staffpeople-box__card-description">
                             Juan David Orjuela
                             </h1>
                             <h2>
                             Director Comercial
                             </h2>
                             <p>
                             juan.orjuela@9alliance.co
                             </p>
                        </div>
                    </div>

                    <div className="staffpeople-box__card   staffpeople-box__card_up">
                        <div className="staffpeople-box__card-image">
                            <img alt="Eliana Forero" src="./img/staff5.png" />
                        </div>

                        <div className="staffpeople-box__card-description">
                             <h1 className="staffpeople-box__card-description">
                             Eliana Forero
                             </h1>
                             <h2>
                             Directora Jurídica
                             </h2>
                             <p>
                             eliana.forero@9alliance.co
                             </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}