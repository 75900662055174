import "../src/App.scss";
import "../src/index.css";
import Home from './Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Us from './components/Us';
import Services from './components/Services';
import ServiceDetail from './components/ServiceDetail';
import Bic from './components/Bic';
import Contact from './components/Contact';
import Quality from './components/Quality';
import { useState } from "react";
import Header from "./components/Header";

export default function App(){

    const [lang, setLang] = useState("esp");
    const settings = {
        lng: lang
    }

    function useSetLang () {
        if(lang === "esp")setLang("eng");
        else setLang("esp");
    };

    return (
        <BrowserRouter>
            <Header lang={settings.lng} setLng={useSetLang}/>
            <Routes>
                <Route path='services/:serviceId' element={<ServiceDetail  settings={settings} setLng={useSetLang}/>}/>
                <Route path='/services/' element={<Services settings={settings} setLng={useSetLang}/>}/>
                <Route path='/us' element={<Us settings={settings} setLng={useSetLang}/>}/>
                <Route path='/contact' element={<Contact settings={settings} setLng={useSetLang}/>}/>
                <Route path='/bic/' element={<Bic settings={settings} setLng={useSetLang}/>}/>
                <Route path='/quality' element={<Quality settings={settings} setLng={useSetLang}/>}/>
                <Route path='/' element={<Home settings={settings} setLng={useSetLang}/>} />
                <Route path="*" element={<Home settings={settings} setLng={useSetLang}/>} />
            </Routes>
        </BrowserRouter>
    );
}