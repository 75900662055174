export default function NegociosInternacionales(props){
    return (
        <div className="service-body-down">
                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv02_1.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Inversión Extranjera en Colombia:" : "Foreign Investment in Colombia:"}</h3>
                        <p>{props.lang === "esp" ? "Evaluamos de forma integral tus opciones de inversión extranjera y brindamos asesoría completa en registro, protección, facilidades, tratados y acuerdos comerciales y tributarios." : "We comprehensively evaluate your foreign investment options and provide complete advice on registration, protection, facilities, trade and tax treaties and agreements."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv02_2.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Inversión Colombiana en el exterior:" : "Colombian investment abroad:"}</h3>
                        <p>{props.lang === "esp" ? "Te orientamos para que logrees invertir en el exterior, mediante una asesoría integral en derecho de la empresa, migratorio, comercial, tributario, cambiario, aduanero y laboral, para que así establezca su capital de manera eficaz y segura." : "We guide you so that you can invest abroad, through comprehensive advice on business, immigration, commercial, tax, exchange, customs and labor law, so that you can establish your capital effectively and safely."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv02_3.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Comercio Exterior:" : "Foreign trade:"}</h3>
                        <p>{props.lang === "esp" ? "Te acompañamos en cada una de las etapas de tu operación de comercio exterior e identificamos los riesgos legales asociados para controlarlos de modo oportuno." : "We accompany you in each of the stages of your foreign trade operation and we identify the associated legal risks to control them in a timely manner."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv02_4.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Cambios Internacionales:" : "International Exchanges:"}</h3>
                        <p>{props.lang === "esp" ? "Te asesoramos en procedimientos de importación y exportación, así como en temas de endeudamiento externo, inversión extranjera, cumplimiento de avales y garantías, servicios y operaciones en moneda extranjera entre residentes. Cubrimos en el ámbito legal y operativo el cumplimiento derivado del uso de cuentas de compensación." : "We advise you on import and export procedures, as well as on issues of external indebtedness, foreign investment, compliance with endorsements and guarantees, services and operations in foreign currency between residents. We cover in the legal and operational scope the compliance derived from the use of compensation accounts."}</p>
                    </div>
                </div>


                <div className="service-body__rack">
                    <div className="service-body__rack-img">
                        <img src="../../img/serv02_5.png" />
                    </div>
                    <div className="service-body__rack-txt">
                        <h3>{props.lang === "esp" ? "Aduanas:" : "Customs:"}</h3>
                        <p>{props.lang === "esp" ? "Te brindamos asesorías con una adecuada planeación aduanera y te asistimos en la obtención de la certificación OEA. Realizamos análisis integral de los mecanismos para la aplicación de las herramientas jurídicas aduaneras: importaciones, exportaciones, tránsito aduanero, zonas francas, acuerdos de integración y tratados de libre comercio." : "We provide advice with customs planning and we assist you in obtaining the AEO certification. We carry out a comprehensive analysis of the mechanisms for the application of customs legal tools: imports, exports, customs transit, free zones, integration agreements and free trade agreements."}</p>
                    </div>
                </div>
            </div>
    );
}