import { Link } from "react-router-dom";

export default function Socials(){
    return(
        <div className="contact-form__socials">
            <a href="https://www.facebook.com/9Alliance" target="_blank" className="contact-form__socials-link">
                <img src="/img/fb.png" />
            </a>

            <a href="https://www.instagram.com/9abic/" target="_blank"  className="contact-form__socials-link">
                <img src="/img/ig.png" />
            </a>

            <a href="https://twitter.com/9Alliance" target="_blank"  className="contact-form__socials-link">
                <img src="/img/tw.png" />
            </a>

            <a href="https://www.linkedin.com/company/2739399/admin/" target="_blank"  className="contact-form__socials-link">
                <img src="/img/in.png" />
            </a>
        </div>
    );
}