import { Link } from "react-router-dom";

export default function ServiceMenu(props){
    return(
        <div className="jumbo-menubox">
                    <div className="jumbo-menubox__nav">
                        <Link to="/services/business-law" className="jumbo-menubox__button">
                            <div className="jumbo-menubox__button-txt"  >
                                { props.lang === "esp" ? "Derecho Empresarial" : "Business Law"}
                            </div>
                        </Link>

                        <Link to="/services/international-business" className="jumbo-menubox__button">
                            <div  className="jumbo-menubox__button-txt"  >
                            { props.lang === "esp" ? "Negocios Internacionales" : "International Business"}
                            </div>
                        </Link>

                        <Link to="/services/softlanding" className="jumbo-menubox__button">
                            <div  className="jumbo-menubox__button-txt"  >
                            Softlanding
                            </div>
                        </Link>

                        <Link to="/services/corporate-sustainability" className="jumbo-menubox__button">
                            <div  className="jumbo-menubox__button-txt">
                            { props.lang === "esp" ? "Sostenibilidad Empresarial" : "Corporate Social Responsability and sustainability"}
                            </div>
                        </Link>
                    </div>
                </div>
    );
}