export default function StatsBoard(props){
    return (
        <div className="statboard">
            <div className="statboard-box">
            <div className="statboard-item">
                    <div className="statboard-item__circle-negative statboard-item__circle">
                        +20
                    </div>
                    <div className="statboard-item__txt">
                    {props.lang === "esp" ? "Años de experiencia" : "Years of experience"}
                    </div>
                </div>

                <div className="statboard-item">
                    <div className="statboard-item__circle">
                        +30
                    </div>
                    <div className="statboard-item__txt">
                    {props.lang === "esp" ? "Compañías con administración delegada" : "Companies with delegated administration"} 
                    </div>
                </div>

                <div className="statboard-item">
                    <div className="statboard-item__circle">
                        +60
                    </div>
                    <div className="statboard-item__txt">
                    {props.lang === "esp" ? "Partners Estratégicos" : "Strategic Partners"} 
                    </div>
                </div>

                <div className="statboard-item">
                    <div className="statboard-item__circle">
                        +220
                    </div>
                    <div className="statboard-item__txt">
                    {props.lang === "esp" ? "Compañías incorporadas en Colombia" : "Companies incorporated in Colombia"} 
                    </div>
                </div>

                <div className="statboard-item">
                    <div className="statboard-item__circle statboard-item__circle-negative">
                        +40
                    </div>
                    <div className="statboard-item__txt">
                    {props.lang === "esp" ? "Millones de dólares en servicios de defensa exitosos" : "Million dollars in successful defense services"} 
                    </div>
                </div>

                <div className="statboard-item">
                    <div className="statboard-item__circle statboard-item__circle-negative">
                        +100
                    </div>
                    <div className="statboard-item__txt">
                    {props.lang === "esp" ? "Millones de dólares gestión del riesgo sancionatorio" : "Million dollars sanction risk management"} 
                    </div>
                </div>

                <div className="statboard-item">
                    <div className="statboard-item__circle">
                        +320
                    </div>
                    <div className="statboard-item__txt">
                      {props.lang === "esp" ? "Millones de dólares en prestaciones de transacciones comerciales" : "Million dollars in benefits of commercial transactions."} 
                    </div>
                </div>
            </div>
        </div>
    );
}