import BicBody from "./BicBody";
import Footer from "./Footer";
import Header from "./Header";
import ServiceHeader from "./ServiceHeader";

export default function Bic( props){
    return (
        <div> 
            <BicBody lang={props.settings.lng} setLng={props.setLng}/>
            <Footer lang={props.settings.lng} setLng={props.setLng}/>
        </div>
    );
}