export default function ServiceHeader(props){

    const headerimage = props.pageId.serviceId;
    let urll = "";
    if(headerimage === "business-law")urll = "../../img/serv1.png";
    else if(headerimage === "international-business")urll = "../../img/serv2.png";
    else if(headerimage === "softlanding")urll = "../../img/serv3.png";
    else if(headerimage === "corporate-sustainability")urll = "../../img/serv4.png";
    return (
        <div className="service-header">
            <img src={urll} />
        </div>
    );
}